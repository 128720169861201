var diskChart, memChart, cpuChart, netSentChart, netRecChart;
var diskDataTable, memDataTable, cpuDataTable, netSentDataTable, netRecDataTable;
var canRefreshButton = true;

function initLineCharts() {
    $(".chart_div").css("height", "30vh");

    diskChart = new google.visualization.LineChart(document.getElementById('diskChart'));
    memChart = new google.visualization.LineChart(document.getElementById('memChart'));
    cpuChart = new google.visualization.LineChart(document.getElementById('cpuChart'));
    netSentChart = new google.visualization.LineChart(document.getElementById('netSentChart'));
    netRecChart = new google.visualization.LineChart(document.getElementById('netRecChart'));

    updateLineCharts();
    setInterval(updateLineCharts, 15000);
}

function redrawCharts() {
  var options = {
    hAxis: { title: 'Time' },
  };

  options.vAxis = { title: 'Usage (%)', maxValue: 100 };
  diskChart.draw(diskDataTable, options);
  memChart.draw(memDataTable, options);
  cpuChart.draw(cpuDataTable, options);
  options.vAxis = { title: 'Sent (Mbps)', maxValue: 30 };
  netSentChart.draw(netSentDataTable, options);
  options.vAxis = { title: 'Received (Mbps)', maxValue: 30 };
  netRecChart.draw(netRecDataTable, options);
}

function updateLineCharts() {
    $.ajax({
      url: "/home/system_info",
      type: "get",
      success: function(d) {
        // console.log(d);
        updateDiskStatus(d);
        updateMemoryStatus(d);
        updateCPUStatus(d);
        updateNetworkStatus(d);
        redrawCharts();
        // $('span#cpuUsage').html(d.cpuUsage.toFixed(2));
      }
    });

}

function updateDiskStatus(d) {
  diskDataTable = new google.visualization.DataTable();
  diskDataTable.addColumn('datetime', 'Time');

  var diskData = [];
  var diskDataColumns = [];
  var spanTotal = 0, spanUsed = 0, spanAvail = 0;

  for(var i=0; i<d.length; i++) {
      var disk_usage = JSON.parse(d[i].disk_usage);
      var totals = { };

      for(var j=0; j<disk_usage.length; j++) {
        var part_name = disk_usage[j]["part_name"];
        if (diskDataColumns.indexOf(part_name) == -1) {
          diskDataColumns.push(part_name);
          diskDataTable.addColumn('number', part_name);
        }
        var total = disk_usage[j]["total"], used = disk_usage[j]["used"], available = disk_usage[j]["available"];
        if (i == d.length-1) {
          spanTotal += total/(1024*1024);
          spanUsed += used/(1024*1024);
          spanAvail += available/(1024*1024);
        }
        totals[part_name] = [used, total];
      }
      diskData.push(totals);
  }

  for(var i=0; i<diskData.length; i++) {
      var timestamp = new Date(d[i]["created_at"]);
      var row = [ timestamp ];

      for(var j=0; j<diskDataColumns.length; j++) {

        var column = diskDataColumns[j];

        if (diskData[i].hasOwnProperty(column)) {
          var values = diskData[i][column];
          row.push(100*values[0]/values[1]);
        } else {
          row.push(0);
        }

      }
      diskDataTable.addRow(row);
  }

  $('span#diskTotal').html(spanTotal.toLocaleString() + " GB");
  $('span#diskUsed').html(spanUsed.toLocaleString() + " GB");
  $('span#diskAvail').html(spanAvail.toLocaleString() + " GB");

  diskDataTable.sort([{column: 0}]);
}

function updateMemoryStatus(d) {
  memDataTable = new google.visualization.DataTable();

  var memDataColumns = [];
  var spanTotal = 0, spanUsed = 0, spanAvail = 0;

  memDataTable.addColumn('datetime', 'Time');
  memDataTable.addColumn('number', 'Usage');

  for(var i=0; i<d.length; i++) {
      var mem_usage = JSON.parse(d[i].mem_usage);
      var timestamp = new Date(d[i]["created_at"]);

      var row = [ timestamp, 100*mem_usage["memUsed"]/mem_usage["memTotal"] ];

      memDataTable.addRow(row);

      if (i == d.length-1) {
        spanTotal = mem_usage["memTotal"];
        spanUsed = mem_usage["memUsed"];
        spanAvail = mem_usage["memFree"];
      }
  }
  $('span#memTotal').html(spanTotal.toLocaleString() + " MB");
  $('span#memUsed').html(spanUsed.toLocaleString() + " MB");
  $('span#memFree').html(spanAvail.toLocaleString() + " MB");

  memDataTable.sort([{column: 0}]);
}

function updateCPUStatus(d) {
  cpuDataTable = new google.visualization.DataTable();

  var cpuDataColumns = [];
  var spanUsed = 0;

  cpuDataTable.addColumn('datetime', 'Time');
  cpuDataTable.addColumn('number', 'Usage');

  for(var i=0; i<d.length; i++) {
      var timestamp = new Date(d[i]["created_at"]);
      var cpu_usage = parseFloat(d[i].cpu_usage);

      var row = [ timestamp, cpu_usage ];

      cpuDataTable.addRow(row);
  }

  $('span#cpuUsed').html(parseFloat(d[d.length-1].cpu_usage).toFixed(2) + " %");

  cpuDataTable.sort([{column: 0}]);
}

function updateNetworkStatus(d) {
  netSentDataTable = new google.visualization.DataTable();
  netRecDataTable = new google.visualization.DataTable();

  netSentDataTable.addColumn('datetime', 'Time');
  netRecDataTable.addColumn('datetime', 'Time');

  var netData = [];
  var netDataColumns = [];
  var spanTotal = 0, spanSent = 0, spanRec = 0;

  for(var i=0; i<d.length; i++) {
      var network_usage = JSON.parse(d[i].network_usage);
      var totals = { };

      for(var j=0; j<network_usage.length; j++) {
        var if_name = network_usage[j]["if_name"];
        if (netDataColumns.indexOf(if_name) == -1) {
          netDataColumns.push(if_name);
          netSentDataTable.addColumn('number', if_name);
          netRecDataTable.addColumn('number', if_name);
        }
        var total = parseFloat(network_usage[j]["total"]),sent = parseFloat(network_usage[j]["sent"]), rec = parseFloat(network_usage[j]["rec"]);
        if (i == d.length-1) {
          spanTotal += total;
          spanSent += sent;
          spanRec += rec;
        }
        totals[if_name] = [sent, rec];
      }
      netData.push(totals);
  }

  for(var i=0; i<netData.length; i++) {
    var timestamp = new Date(d[i]["created_at"]);
    var row_sent = [ timestamp ], row_rec = [ timestamp ];

    for(var j=0; j<netDataColumns.length; j++) {

      var column = netDataColumns[j];

      if (netData[i].hasOwnProperty(column)) {
        var values = netData[i][column];
        row_sent.push(values[0]);
        row_rec.push(values[1]);
      } else {
        row_sent.push(0);
        row_rec.push(0);
      }

    }
    netSentDataTable.addRow(row_sent);
    netRecDataTable.addRow(row_rec);
  }

  $('span#netTotal').html(spanTotal.toLocaleString() + " Mbps");
  $('span#netSent').html(spanSent.toLocaleString() + " Mbps");
  $('span#netRec').html(spanRec.toLocaleString() + " Mbps");

  netSentDataTable.sort([{column: 0}]);
  netRecDataTable.sort([{column: 0}]);
}

function updateLog() {
    $.ajax({
      url: "/log/log_contents",
      type: "get",
      success: function(d) {
        $("#log_area").text(d.data);
      }
    });
    setTimeout(updateLog, 10000);
}

function updateEvents() {

  $.ajax({
    url: "/home/events_info",
    type: "get",
    success: function(d) {
      // console.log(d);
      for(var i=0; i<d.length; i++) {
        var ev = d[i];

        var stats = JSON.parse(ev.stats);

        if ("out_time" in stats) {
          $('#event-'+ev.id+'-time').text(stats["out_time"].split(".")[0]);
        }
        if ("frame" in stats) {
          $('#event-'+ev.id+'-frame').text(parseInt(stats["frame"]).toLocaleString());
        }
        if ("drop_frames" in stats) {
          $('#event-'+ev.id+'-dropped').text(parseInt(stats["drop_frames"]).toLocaleString());
        }
        if ("dup_frames" in stats) {
          $('#event-'+ev.id+'-duplic').text(parseInt(stats["dup_frames"]).toLocaleString());
        }

        $('#event-'+ev.id+'-state').text(ev.current_state);
      }
    }
  });
}

function updateEventsThumbs() {
  $('.event-thumb').each(function () {
    if (this.src.length > 0) {
      var base_path = (this.src.indexOf("?")>-1) ? this.src.split("?")[0] : this.src;
      this.src = base_path + "?" + (new Date().getTime());
    }
  });
}

function setStatusElements() {
  $("#refresh-list-status").text("Please refresh the list").attr("class", "");
  $("#refresh-list-response").html("");

  switch ($('select#event_protocol').val()) {
    case 'udp':
      $('#refresh-list-title').text("Available services");
      $('#refresh-list-response').show();
      break;
    case 'rtmp':
      $('#refresh-list-title').text("Event status");
      $('#refresh-list-response').hide();
      break;
  }
}

function toggleInputType() {
  var input_type = $("#event_input_type").val();
  if (input_type == "rf") {
    $("div.ip_area").hide();
    $("div.rf_area").show();
  } else if (input_type == "ip") {
    $("div.rf_area").hide();
    $("div.ip_area").show();
  }
  setInputElements();
}

function setInputElements() {
  var input_type = $("#event_input_type").val();
  if (input_type == "ip") {
    switch ($('select#event_protocol').val()) {
      case 'udp':
        $('div.rtmp_area').hide();
        $('#complete_url_area').hide();
        $('#ip_port_area').show();
        $('#mpegts_udp_area').show();
        break;
      case 'rtmp':
        $('div.rtmp_area').show();
        $('#complete_url_area').hide();
        $('#ip_port_area').show();
        $('#mpegts_udp_area').hide();
        break;
      case 'rtsp':
        $('div.rtmp_area').hide();
        $('#ip_port_area').hide();
        $('#complete_url_area').show();
        $('#mpegts_udp_area').hide();
        break;
    }
  } else if (input_type == "rf") {
    $('#mpegts_udp_area').show();
  }
}

function setRecordingElements() {
  let rec_duration = $('div#event_recording_duration_div');
  if ($('input#event_is_recording').is(":checked")) {
    rec_duration.show();
  } else {
    rec_duration.hide();
    rec_duration.val("");
  }
}

function updateServiceIDsList() {

  $("#refresh-list-status").text("Retrieving program list...").attr("class", "");
  $("#refresh-list-response").html("");

  var input_type = $("#event_input_type").val();
  var request_data;
  if (input_type == "rf")  {
    request_data = {
      'input_type': 'rf',
      'tuner': $('#event_rf_tuner').val(),
      'channel': $('#event_rf_channel').val()
    };
  } else if (input_type == "ip") {
    request_data = {
      'input_type': 'ip',
      'protocol': $('#event_protocol').val(),
      'ip': $('#event_ip').val(),
      'port': $('#event_port').val()
    };
  }

  $.ajax({
    url: "/home/get_service_ids_list",
    type: "get",
    data: request_data,
    success: function(d) {
      var services = d["services"],
          errors = d["errors"];
      if (services.length == 0) {
        var msg = "Could not find any programs.";

        if (errors.length > 0) {
          msg += "<br><b>Error(s): </b>";
          msg += errors.join(". ");
        }

        $("#refresh-list-status").html(msg).addClass("error");
      } else {
        $("#refresh-list-status").text("List updated.").addClass("success");
        var list = "<ul>";
        for(var i=0; i<services.length; i+=2) {
          list += "<li>" + services[i] + " - " + ( (services[i+1].length>0) ? services[i+1] : "undefined name" ) + "</li>";
        }
        list += "</ul>";
        $("#refresh-list-response").html(list);
      }

      $("#refresh-list-button").css("opacity", "1");
      canRefreshButton = true;
    },
    error: function(xhr) {
      $("#refresh-list-status").text("No response from the server.").addClass("error");
      $("#refresh-list-button").css("opacity", "1");
      canRefreshButton = true;
    }
  });
}

function updateRTMPEventStatus() {

  $("#refresh-list-status").text("Retrieving event status...").attr("class", "");

  $.ajax({
    url: "/home/get_rtmp_event_status",
    type: "get",
    data: {
      rtmp_app_name: $('#event_rtmp_app_name').val(),
      event_name: $('#event_name').val(),
      ip: $('#event_ip').val(),
      port: $('#event_port').val()
    },
    success: function(d) {
      var error = d["error"];
      var msg = (error == null) ? "Event is <b>active</b>" : error;

      $("#refresh-list-status").html(msg).addClass( (error == null) ? "success": "error"  );
      $("#refresh-list-button").css("opacity", "1");
      canRefreshButton = true;
    },
    error: function(xhr) {
      $("#refresh-list-status").text("No response from the server.").addClass("error");
      $("#refresh-list-button").css("opacity", "1");
      canRefreshButton = true;
    }
  });
}

function updateEventUrlStatus() {

  $("#refresh-list-status").text("Retrieving event status...").attr("class", "");

  $.ajax({
    url: "/home/get_event_url_status",
    type: "get",
    data: {
      url: $('#event_protocol').val() + "://" + $('#event_complete_url').val()
    },
    success: function(d) {
      var error = d["error"];
      var msg = (error == null) ? "Event is <b>active</b>" : error;

      $("#refresh-list-status").html(msg).addClass( (error == null) ? "success": "error"  );
      $("#refresh-list-button").css("opacity", "1");
      canRefreshButton = true;
    },
    error: function(xhr) {
      $("#refresh-list-status").text("No response from the server.").addClass("error");
      $("#refresh-list-button").css("opacity", "1");
      canRefreshButton = true;
    }
  });
}

function validate_ip_address(el)
{
  var valid = true;

  if (el.disabled) {
    el.style.borderColor = '';
    return true;
  } else
  if (el.value.length==0) {
    valid = el.classList.contains('validate-empty');
  } else {
    valid = (/^(25[0-5]|2[0-4][0-9]|[01]?[0-9][0-9]?)\.(25[0-5]|2[0-4][0-9]|[01]?[0-9][0-9]?)\.(25[0-5]|2[0-4][0-9]|[01]?[0-9][0-9]?)\.(25[0-5]|2[0-4][0-9]|[01]?[0-9][0-9]?)$/.test(el.value));
  }

  if (valid)
    el.style.borderColor = "green";
  else
    el.style.borderColor = "red";

  return valid;
}

function update_ip_fields(el){

  var ip_fields, interface_fields, static_fields, dns_fields;
  interface_fields = $(el).closest('.interface-fields');
  static_fields = interface_fields.find('input[name*=\\[ip\\]],input[name*=\\[netmask\\]],input[name*=\\[gateway\\]]');
  dns_fields = interface_fields.find('input[name*=\\[nameserver1\\]],input[name*=\\[nameserver2\\]]');
  ip_fields = $().add(static_fields).add(dns_fields);

  ip_fields.off('change keyup');

  var val = $(el).find('option:selected').val();
  switch (val) {
    case 'static':
      ip_fields.prop('disabled', false);
      ip_fields.addClass('validate-ip');
      ip_fields.on('change keyup', function () {
        validate_ip_address(this);
      });
    break;

    case 'dhcp':
      static_fields.val('');
      static_fields.prop('disabled', true);
      dns_fields.prop('disabled', false);
      ip_fields.removeClass('validate-ip');
    break;

    case 'disabled':
      ip_fields.val('');
      ip_fields.prop('disabled', true);
      ip_fields.removeClass('validate-ip');
    break;
  }

  ip_fields.each(function () {
    validate_ip_address(this);
  });
}

function set_routes_ip_fields() {
  var route_ip_fields = $('input#route_destination,input#route_gateway,input#route_netmask');
  route_ip_fields.addClass('validate-ip');
  route_ip_fields.each(function () {
    validate_ip_address(this);
  });
  route_ip_fields.on('change keyup', function () {
    validate_ip_address(this);
  });
}

function set_clickable_fields() {
  var rows = $('#routes_view table tr:gt(0)');

  rows.find('td:eq(0),td:eq(1),td:eq(2),td:eq(4)').each(function(){
    if ($(this).text().length > 0)
      $(this).css('cursor', 'pointer');
  });


  rows.find('td:eq(0)').click(function () {
    $('#route_destination').val($(this).text());
  });

  rows.find('td:eq(1)').click(function () {
    $('#route_gateway').val($(this).text());
  });

  rows.find('td:eq(2)').click(function () {
    $('#route_netmask').val($(this).text());
  });

  rows.find('td:eq(4)').click(function () {
    $('#route_interface').val($(this).text());
  });
}

$(function() {

  $.ajaxSetup({
    headers: { 'X-CSRF-Token': $('meta[name="csrf-token"]').attr('content') }
  });

  $('.js-fullheight').css('height', $(window).height());

  $(window).resize(function(){
    $('.js-fullheight').css('height', $(window).height());
  });

  $('#sidebarCollapse').on('click', function () {
      $('#sidebar').toggleClass('active');
  });

  // sidebar
  $('#sidebarMenu a[href="' + this.location.pathname + '"]:eq(0)').addClass('active');

  // home
  if (location.pathname == '/' || location.pathname.indexOf("/home") != -1) {
    // charts
    google.charts.load('current', {packages: ['corechart', 'line']});
    google.charts.setOnLoadCallback(initLineCharts);

    $(window).resize(function(){
      redrawCharts();
    });

    // events
    setInterval(updateEvents, 8000);
    setInterval(updateEventsThumbs, 25000);
  }

  // logs
  if (location.href.indexOf("/log") != -1) {
    $("select#log_level").on("change", function() {
      document.getElementById("submit_log_level").click();
    });
    updateLog();
  }

  // events
  if (location.pathname.indexOf("/events/") != -1) {

    if ( (location.pathname.indexOf("/new") != -1) || (location.pathname.indexOf("/edit") != -1) ) {
      setStatusElements();
      setInputElements();
      setRecordingElements();
      toggleInputType();

      $('select#event_protocol').on('change keyup', function () {
        setStatusElements();
        setInputElements();
        $('#event_rtmp_app_name').val("");
        $('#event_ip').val("");
        $('#event_port').val("");
        $('#event_complete_url').val("");
      });

      $('input#event_is_recording').on('change', function () {
        setRecordingElements();
      });

      $('select#event_input_type').on('change', function () {
        toggleInputType();
      });

      $("#refresh-list-button").click(function () {
        if (!canRefreshButton)
          return;

        canRefreshButton = false;
        $("#refresh-list-button").css("opacity", "0.5");

        switch ($('select#event_protocol').val()) {
          case 'udp':
            updateServiceIDsList();
            break;
          case 'rtmp':
            updateRTMPEventStatus();
            break;
          case 'rtsp':
            updateEventUrlStatus();
            break;
        }
      });
    }
  }

  // settings
  if (location.pathname.indexOf("/settings/") != -1) {

    // loads the current tab from sessionStorage and display the contents
    var current_settings_tab = sessionStorage['current_settings_tab'];
    var current_tab_element = (current_settings_tab) ? document.getElementById(current_settings_tab) : null;
    if (current_tab_element) {
      current_tab_element.click();
    } else {
      sessionStorage['current_settings_tab'] = $('#formTab a[role=tab].active').attr('id');
    }

    $('#formTab').show();
    $('#formTabContent').show();

    // when tab is clicked, save the new tab
    $('#formTab a').click(function(){
      sessionStorage['current_settings_tab'] = $(this).attr('id');
    });

    $('select[name*=interf]').each(function () {
      update_ip_fields(this);
    });

    set_routes_ip_fields();
    set_clickable_fields();

    $('select[name*=interf]').on('change keyup', function () {
      update_ip_fields(this);
    });

    // validate form on submit
    $('form').submit(function() {
      var valid = true;
      $(this).find('.validate-ip').each(function () {
        if (!validate_ip_address(this)) {
          valid = false;
          return false;
        }
      });
      if (!valid)
        return false;
    });
  }

});
